<template>
  <container style="max-width: 1000px;">
    <v-card>
      <v-card-title>Importar Lead {{ external_reference }}</v-card-title>
      <v-card-text>
        Erro(s) de validação ao importar a lead:
        <v-list>
          <template v-for="[error, messages] in validation_errors">
            <v-list-item v-for="(message, index) in messages" :key="error + index">
              <v-list-item-content>
                <v-list-item-title class="red--text">
                  <v-icon color="red">mdi-circle-small</v-icon> {{ message }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>

    <!-- Action Bar -->
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar elevation="0">
            <v-toolbar-title> </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="$router.go(-1)" class="mr-2" elevation="0">
              Cancelar
            </v-btn>
            <v-btn 
              :to="{
                  name: 'lead_new_import_robot_form',
                  params: {
                      job_id: jobId
                  }
              }"
              color="primary">Continuar</v-btn>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>
  </container>
</template>

<script>
export default {
  computed: {
    jobId() {
      return this.$route.params.job_id;
    },
    lead() {
      return this.$store.getters['lead_form/getLead'];
    },
    external_reference() {
      return this.lead?.external_reference;
    },
    insurerId() {
      return this.lead?.insurer?.id;
    },
    validation_errors() {
      const errors = this.$store.getters['lead_form/getValidationErrors'] || {};
      return Object.entries(errors);
    }
  },

  methods: {
    async getRobotPayload() {
      this.$store.commit('setLoading', {
          loading: true
      });

      try {
        await this.$store.dispatch('lead_form/importRobotPayload', { jobId: this.jobId });
      } catch(error) {
          if(error.response.status == 404) {
              this.importNotFound = true;
          }
          this.$store.commit('sendMessage', {
              text: 'Erro ao importar dados do robô',
              color: 'error'
          });
      } finally {
          this.$store.commit('setLoading', {
              loading: false
          });
      }
    },
  },

  created() {
    this.getRobotPayload();
  }
}
</script>

<style>

</style>